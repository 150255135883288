import classNames from "classnames";
import { FC, ReactElement, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from '../../css/pages/home.module.scss';
import pageStyle from "../../css/pages/page.module.scss";
import { getInnerWrapperStyle, getOuterWrapperStyle } from "../../js/style-utils";
import Login from "../common/login/Login";
import Button from "../common/ui/Button";
import Pulser from "../common/ui/Pulser";
import ScrollArrow from "../common/ui/ScrollArrow";
import TransitionIn from "../common/ui/TransitionIn";

interface IProps {
  first?: boolean;
  colorIndex?: number;
  loggedIn: boolean;
  loginRoute: string;
  onLoginSuccess: Function;
};

const Home: FC<IProps> = ({ first = false, colorIndex, loggedIn, loginRoute, onLoginSuccess }): ReactElement => {
  const navigate = useNavigate();
  const outerWrapperStyle = getOuterWrapperStyle(first, colorIndex);
  const innerWrapperStyle = getInnerWrapperStyle(first);
  const headShotRef = useRef<HTMLDivElement | null>(null);

  const outerWrapperStyleWithImage = classNames({
    [outerWrapperStyle]: true,
    [style.bgImage]: true
  });

  const wrapperStyle = classNames({
    [pageStyle.wrapper]: true,
    [style.wrapper]: true
  });

  const [requestLogin, setRequestLogin] = useState(false);

  const onSuccessLogin = (): void => {
    setRequestLogin(false);
    onLoginSuccess();
  };

  const onCancelLogin = (): void => {
    setRequestLogin(false);
  };

  const onLoginClicked = (): void => {
    setRequestLogin(true);
    // bypass login for development use only
    if (process.env.REACT_APP_LOGIN === 'FALSE') onSuccessLogin();
  };

  const displayHeadShot = () => {
    if (headShotRef && headShotRef.current) {
      headShotRef.current.style.backgroundImage = 'url(/images/head-shot.png)';
    }
  };

  // The bg color is not used in the outer wrapper for the home page.
  // Instead, a gradient is added to another encasing div. This allows
  // the head shot image to be used in the outer wrapper instead,
  // covering the full width of the screen.
  return (
    <>
      <div className={style.bgGradient}>
        <div ref={headShotRef} className={outerWrapperStyleWithImage}>
          <div className={wrapperStyle}>
            <div className={innerWrapperStyle}>
              <div className={style.page}>
                <TransitionIn useDelay={0.4}>
                  <h1 className={style.malcName}>malc adams.</h1>
                </TransitionIn>
                <TransitionIn useDelay={0.6}>
                  <p className={style.title}>front-end developer</p>
                </TransitionIn>
                <TransitionIn useDelay={0.8}>
                  <p className={style.about}><i>Turning beautiful concepts into delightful interactions</i></p>
                </TransitionIn>
                {!loggedIn && !requestLogin &&
                  <TransitionIn useDelay={1}>
                    <Pulser>
                      <Button
                        className={style.loginButton}
                        label="Enter"
                        bgcolor="#20b2aa"
                        bgcolorHover="#18ebe0"
                        callback={() => onLoginClicked()}
                      />
                    </Pulser>
                  </TransitionIn>
                }
                <ScrollArrow play={loggedIn} onClick={() => navigate('/#showcase')} />
                {!loggedIn && requestLogin &&
                  <Login loginRoute={loginRoute} onSuccess={onSuccessLogin} onCancel={onCancelLogin} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <img id="headShot" style={{ display: 'none' }} onLoad={displayHeadShot} src="/images/head-shot.png" alt=" " />
    </>
  );
}

export default Home;
