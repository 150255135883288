import 'whatwg-fetch'; // allows for window.fetch

interface IEmail {
  name: string;
  email: string;
  company?: string | undefined;
  message: string;
};

const postEmail = async ({ name, email, company, message }: IEmail): Promise<void> => {
  return new Promise((resolve, reject) => {
    // Development mode.
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      setTimeout(() => {
        if (name.toLowerCase() === 'error') reject();
        else resolve();
      }, 2000);
    } else {
      if (typeof company === 'undefined') company = 'Not specified';

      window.fetch("https://formsubmit.co/ajax/92e123a146b19abac5cce88667fd0aa8", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          name: name,
          company: company,
          message: message,
          email: email,
          subject: 'Message from Portfolio Website',
          from_name: 'Portfolio',
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.hasOwnProperty('success') && data.success === 'true') resolve();
          else reject();
        })
        .catch(() => reject());
    }
  });
};

export default postEmail;